import { Container, Row, Col, Text, Card, Grid, Spacer } from '@nextui-org/react';
import ProductSection from './ProductSection';

const products = [
    {
        "name": "Capital Forecast",
        "description": "A pressable card.",
    },
    {
        "name": "Homeflix.io",
        "description": "A pressable card.",
    }
]

const ProductPage = (props) => {

  return (
    <Container>
    <Col>
    <Spacer y={16} />
    <Text
        h1
        size={60}
        css={{
          textGradient: "45deg, $blue600 -20%, $pink600 50%",
          textAlign: "center"
        }}
        weight="bold"
      >
    Features
    </Text>
    <Text css={{textAlign: "center"}}>Smart, simple tools that provide powerful insight.</Text>
    <Spacer y={16} />
    </Col>
    <Col>
    <ProductSection
      name="Make a Plan"
      description="Decide where you want to be, then setup your monthly savings goal that will help you get their.  Know of any big expenses coming up?  Add them to your plan and see how they impact your savings."
      img="/images/capital-forecast-home.png"
      features={[
        {
          "name": "Monthly Savings Goals",
          "description": "Based on what you are comfortable saving each month, set a goal and track your progress.",
        },
        {
          "name": "Adjust for One Time Events",
          "description": "Getting a bonus? Putting a down payment on a car?  Add these one time events to your plan and see how they impact your savings.",
        },
        {
          "name": "Use this as your Baseline",
          "description": "This plan is your baseline, and its what Capital Forcast will use to keep you on track.",
        }
      ]}
      />
    </Col>
    <Col>
    <Spacer y={8} />
    <ProductSection
      name="See the Future"
      description="This is where the magic happens. Capital Forecast will analyze your prior Credit Card Payments, Mortgage Payments, your Income and any other recurring activity to predict your future cash flow.  Thinking  about buying a new car?  Add that to your plan and Capital Forecast will predict how that will impact your future cash balances."
      img="/images/capital-forecast-home.png"
      features={[
        {
          "name": "Identify Reccuring Events",
          "description": "Don't manually enter data into a Spreadsheet, we'll identify all recurring events that need to be pulled into the forecast.",
        },
        {
          "name": "Predict a Daily Cash Balance",
          "description": "We will predict how much cash you will have in your bank account each day, so you are never caught off guard again.",
        },
        {
          "name": "Alert you to Issues",
          "description": "Set a predefined limit you never want your cash accounts to drop below, if your forecasted balance ever moves lower, we'll alert you immediately.",
        }
      ]}
      />
    </Col>
    </Container>
  )  
};

export default ProductPage;