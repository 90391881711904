import * as React from 'react';
import MainApp from './MainApp';
// 1. import `NextUIProvider` component
import { NextUIProvider, createTheme } from '@nextui-org/react';
import { ThemeProvider as NextThemesProvider } from 'next-themes';

import './App.css'

const darkTheme = createTheme({
  type: 'dark',
  theme: {
    fonts: {
      sans: 'Poppins, sans-serif',
      body: 'Poppins, sans-serif',
    
    },
    colors: {
      primary: "#7469e8"
    }
  }
})

export default function App({ Component }) {
  return (
    <NextThemesProvider
      defaultTheme="dark"
      attribute="class"
      value={{
        dark: darkTheme.className
      }}
    >
    <NextUIProvider>
      <MainApp />
    </NextUIProvider>
  </NextThemesProvider>
  );
}
