import { Box } from "./Box.js";
import { Navbar, Button, Link, Text, Card, Spacer, Radio, useTheme, Image } from "@nextui-org/react";
import {
  useNavigate,
} from "react-router-dom";

export const Layout = (props) => {
  const {isDark} = useTheme();
  const navigate = useNavigate();
  const path = window.location.pathname;

  console.log(window.location.pathname)

  const collapseItems = [
    "Company",
    "Products",
  ];

  const navigaationItems = [
    {
      name: "Home",
      path: "/"
    },
    {
      name: "Features",
      path: "/features"
    }
  ]

  return (
    <Box
    css={{
      maxW: "100%"
    }}
  >
    <Navbar isBordered={isDark} variant="sticky">
      <Navbar.Toggle showIn="xs" />
        <Navbar.Brand>
          <Image src="/favicon.ico" width={20} />
          <Text b color="inherit" hideIn="xs"
            css={{
              textGradient: "45deg, $primary 30%, $pink600 130%",
              marginLeft: 8
            }}
            >
            Capital Forecast
          </Text>
        </Navbar.Brand>
        <Navbar.Content activeColor={"primary"} hideIn="xs" variant={"default"}>
        {navigaationItems.map((item) => (
          <Navbar.Link onPress={()=> navigate(item.path)} isActive={ window.location.pathname == item.path ? true : false} >{item.name}</Navbar.Link>
        ))}
        </Navbar.Content>
        {/*}
        <Navbar.Content>
          <Navbar.Link color="inherit" >
            Login
          </Navbar.Link>
          <Navbar.Item>
            <Button auto flat as={Link} color={"primary"} href="#">
              Sign Up
            </Button>
          </Navbar.Item>
        </Navbar.Content>
        {*/ }
      <Navbar.Collapse>
      {navigaationItems.map((item) => (
            <Navbar.CollapseItem
              key={item.path}
              activeColor="primary"
              isActive={item.path === window.location.pathname ? true : false}
            >
              <Link
                color="inherit"
                css={{
                  minWidth: "100%",
                }}
                onPress={() => navigate(item.path)}
              >
                {item.name}
              </Link>
            </Navbar.CollapseItem>
          ))}
      </Navbar.Collapse>
      </Navbar> 
    {props.children}
  </Box>
  )  
};
