import { Container, Row, Col, Text, Card, Grid, Spacer } from '@nextui-org/react';


const HomePage = (props) => {

  return (
    <Container>
        <Text
        h1
        size={60}
        css={{
          textGradient: "45deg, $blue600 -20%, $pink600 50%",
          textAlign: "center",
          marginTop: "35vh"
        }}
        weight="bold"
      >
    Plan, Forecast and Build your Wealth
    </Text>
    <Grid.Container gap={2} justify='center'>
    <Grid xs={12} sm={12} md={7}>
      <Col>
    <Text css={{textAlign: 'center'}}>
        Capital Forecast is a financial planning tool that helps you make financial decisions today, without compromising your future.
    </Text>
    <Spacer y={2} />
    <Text css={{textAlign: 'center'}}>
        Currently under development. Please check back for general availability.
    </Text>
    </Col>
    </Grid>
    </Grid.Container>
    </Container>
  )  
};

export default HomePage;