import React from "react";
import { Layout } from "./Layout.js";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";

import  ProductPage  from "./pages/FeaturesPage/FeaturesPage.js";
import HomePage  from "./pages/HomePage/HomePage.js";


export default function App() {

  return (
    <Router>
        <Routes>
            <Route path="/features" element={<Layout><ProductPage/></Layout>} />
            <Route path="/" element={<Layout><HomePage/></Layout>} />
        </Routes>
    </Router>
  )
}
