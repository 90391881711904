import { Container, Row, Col, Text, Card, Spacer, Image, Grid, Badge } from '@nextui-org/react';
import { motion } from "framer-motion";

const  Variants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        duration: 0.8
      }
    }
  };

const viewport={ once: true, amount: .8}
const transition={
  ease: "linear",
  duration: 4
}

const features = [
    {
        "name": "Planning",
        "description": "Setup target savings goals and one time expenses.",
    },
    {
        "name": "Forecast",
        "description": "See exactly what your account balances will be in the future based on past trends.",
    },
    {
        "name": "Powered By Plaid",
        "description": "Automatically sync your financial data by linking your bank accounts with Plaid.",
    },
]
const ProductSection = (props) => {

  return (
    <Col>
    <motion.div
        initial="offscreen"
        whileInView="onscreen"
        className="box"
        viewport={viewport}
        transition={transition}
        >
        <motion.div variants={Variants} className="box">
        <Text
            h2
            css={{
            textGradient: "45deg, $blue600 -20%, $pink600 50%",
            }}
            weight="bold"
        >
        {props.name}
        </Text>
        <Text>{props.description}</Text>
        </motion.div>
    </motion.div>
    <Spacer y={5} />
    <motion.div
        initial="offscreen"
        whileInView="onscreen"
        className="box2"
        viewport={viewport}
        transition={transition}
        >
    <motion.div variants={Variants} className="box2">
    <Grid.Container gap={2} justify='center'>
      <Grid xs={12} md={8}>
        <Image src={props.img} width={"100%"} hideIn="xs"/>
      </Grid>
    </Grid.Container>
    </motion.div>
    </motion.div>
    <Spacer y={5} />
    <motion.div
        initial="offscreen"
        whileInView="onscreen"
        className="features"
        viewport={viewport}
        transition={transition}
        >
    <motion.div variants={Variants} className="features">
    <Grid.Container gap={2} justify='flex-start'>
    {
        props.features.map((feature) => {
            return (
          <Grid xs={12} sm={12} md={4}>
            <Card
                isPressable
                isHoverable
                variant="bordered"
                >
                <Card.Header>
                    <Text h3>{feature.name}</Text>
                </Card.Header>
                <Card.Body>
                    <Text>{feature.description}</Text>
                </Card.Body>
                </Card>
            </Grid>
            )
      })
    }
    </Grid.Container>
    </motion.div>
    </motion.div>
    <Spacer y={5} />
    </Col>


  )  
};

export default ProductSection;